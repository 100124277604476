<script>
import Layout from "../../layouts/main";
import { mapGetters, mapActions } from 'vuex';
import draggable from 'vuedraggable'
import { required } from "@vuelidate/validators";
import useVuelidate from '@vuelidate/core';
import { uuid } from 'vue-uuid';
import StatusColor from '@/views//pages/directories/status-color.vue';

export default {
  setup () {
 
    return { v$: useVuelidate() }
  },
  components: {
    Layout,
    draggable,
    StatusColor
  },
  props:{
    id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      errors: [],
      initializing: false,
      processing: false,
      loading: false,
      inited: false,
      directories:[],
      item: {
        
      }
    };
  },
  validations() {

    const rules = {
      item: {
        name: { required }
      }
    };

    return rules;
  },
  created() {
    this.directories = this.directoriesFromState;
    document.title = this.pagetitle;
    this.init();
  },
  computed :{
    isNew() {
      return this.id.toLocaleLowerCase() === 'new' ? true: false;
    },
    pagetitle () {
      return this.isNew ? this.$t("board.editFormNewItemTitle") : this.$t("board.editFormEditItemTitle");
    },  
    ...mapGetters('boot', {
      appReady: 'ready'
    }),
    ...mapGetters('auth', {
      currentUser: 'currentUser'
    }),
    ...mapGetters('directories', {
      directoriesFromState: 'items'
    }),
    ...mapGetters('boards', {
      boards: 'items'
    }),
    processes() {
      return this.directories.filter(x => x.type === 'Process');
    }
  },
  watch: {
    appReady (newValue, oldValue) {
      if(newValue !== oldValue) {
        this.init();
      }
    }
  },
  methods: {
    ...mapActions('boards', {
      create: 'create',
      update: 'update'
    }),
    populate(source) {
      const o = {};
      source = JSON.parse(JSON.stringify(source));

      o.id = source.id;
      o.name = source.name;
      o.lanes = (source.lanes || []).map(x => {
        const dir = this.processes.find(a => a.id === x.directoryId);
        return {
          id: x.id,
          directory: dir || null,
          status: (dir || { statusList: []}).statusList.find(status => status.id === x.statusId) || null
        }
      });

      this.item = o;
    },
    createDto() {
      let dto = {
        id: this.item.id || uuid.v1(),
        name: this.item.name,
        lanes: this.item.lanes.map(x => ({
          id: x.id,
          name: x.name,
          directoryId: x.directory.id,
          statusId: (x.status || {}).id || null
        })),
      };
      return dto;
    },
    async init() {
      if(!this.appReady) return;

      if(this.initializing || this.inited) {
        return;
      }
    
      this.initializing = true;
      if(this.isNew) {
        this.populate({
        
        });
      } else {
        this.populate(this.boards.find(x => x.id === this.id));
      }
      this.inited = true;
      this.initializing = false;
    },
    async submit () {
      let isValid = await this.v$.$validate();
      this.errors = [];

      if(this.item.lanes.length === 0) {
        this.errors = [this.$t('board.boardMustHaveAtLeastOneLane')];
        isValid = false;
      } 
      if(this.item.lanes.find(x => !x.directory || !x.status)) {
        this.errors = [this.$t('board.laneHasSeveralRequiredFields')];
        isValid = false;
      }
    
      if(isValid) {
        
        const dto = this.createDto();
  
        let req = this.isNew ? this.create({ dto }) : this.update({ id: this.id, dto: dto});
        req.then(() => {
          this.$toast.open({message: this.$t('common.savedText')});
          this.$router.push({name: 'boards'});
        })
        .catch(error => {
          this.errors = error;
        });
      }
    },
    addLane() {
      const newLane = {
        id: uuid.v1(),
        name: 'New Lane ' + this.item.lanes.length,
        directory: null,
        status: null
      };
      this.item.lanes = [...this.item.lanes, newLane];
    },
    removeLane(lane) {
      this.item.lanes = this.item.lanes.filter(x => x !== lane);
    }
  }
};
</script>

<template>
  <Layout :pagetitle="pagetitle + ( item.name ? (' [' + item.name + ']') : '')" :is-busy="initializing || loading || processing">
   
    <div v-if="inited" class="card mb-4" style="padding-bottom: 40px">
      <div class="card-body">

        <div class="alert mt-4 mb-4 alert-dismissible alert-danger" v-if="errors.length > 0">
          <button type="button" aria-label="Close" class="btn-close" @click="errors=[]"></button>
          <ul class="list-unstyled">
            <li v-for="e in errors" :key="e">{{e}}</li>
          </ul>
        </div>
        <div>
          <div class="row gy-2 gx-3 align-items-center mb-3">
            <div class="col-md-12">
              <label  for="name">{{$t('board.fields.name')}}</label>
              <input type="text" class="form-control" id="name" :placeholder="$t('board.fields.name')" :class="{ 'is-invalid': v$.item.name.$error }" v-model="item.name"/>
              <div class="invalid-feedback">
                {{ $t("common.pleaseEnter") }} {{$t('board.fields.name')}}
              </div> 
            </div>
          </div>
        </div>  
        <ul class="nav nav-tabs mt-4" role="tablist">
          <li class="nav-item">
            <a
              class="nav-link active"
              data-bs-toggle="tab"
              href="#lanes"
              role="tab"
            >
              <span>{{$t('board.fields.lanes')}}</span>
            </a>
          </li>
        </ul> 
        <div class="tab-content py-3 text-muted">
          <div class="tab-pane active " id="lanes" role="tabpanel">
            <div class="d-flex flex-wrap">
              <div class="">
                <a
                  class="btn btn-sm btn-light"
                  role="button"
                  @click="addLane()"
                >
                  <font-awesome-icon icon="fa-solid fa-plus" />
                  <div class="text-sm-end d-none d-md-inline-block ms-2">
                    {{ $t("common.newRecord") }}
                  </div>
                </a>
              </div>
            </div>
            <div class="bg-light p-2 mt-2">
              <table
                class="table project-list-table table-nowrap align-middle table-borderless"
                style=""
              >
                <thead>
                  <tr>
                    <th scope="col" style="width: 60px">
                      <i class="mdi mdi-drag-variant"></i>
                    </th>
                    <!-- <th scope="col">{{ $t("board.fields.laneName") }}</th> -->
                    <th scope="col">{{ $t("board.fields.directory") }}</th>
                    <th scope="col">{{ $t("board.fields.status") }}</th>
                    <th scope="col" style="width: 80px"></th>
                  </tr>
                </thead>
                <tbody  v-if="item.lanes.length === 0">
                  <tr>
                    <td colspan="100">
                      <em class="text-muted">
                        {{ $t("common.noRecordsFound") }}
                      </em>
                    </td>
                  </tr>
                </tbody>
                <draggable
                  tag="tbody"
                  v-model="item.lanes" 
                  group="lanes" 
                  @start="drag=true" 
                  @end="drag=false" 
                  handle=".drag-area"
                  item-key="index"
                >
                  <template #item="{element}">
                    <tr>
                      
                      <td class="drag-area">
                        <a href="javascript:void(0)" >
                          <i class="mdi mdi-drag font-size-18"></i>
                        </a>
                      </td>
                      
                      <!-- <td style="min-width:200px; width:200px">
                        <input class="form-control input-sm" v-model="element.name"/>
                      </td> -->
                      <td >
                        <select class="form-control input-sm" v-model="element.directory" @change="element.status = null">
                          <option :value="null">{{$t('common.nothingSelected')}}</option>
                          <option v-for="dir in processes" :key="dir.id" :value="dir" >{{dir.name}}</option>
                        </select>
                      </td>
                       <td>
                        <div v-if="element.directory">
                          <select class="form-control input-sm" v-model="element.status" @change="element.statusList = []">
                            <option :value="null">{{$t('common.nothingSelected')}}</option>
                            <option v-for="status in element.directory.statusList" :key="status.id" :value="status" >
                              <status-color :color="status.color"></status-color>{{status.name}}
                            </option>
                          </select>
                        </div>
                        
                      </td>
                      <td>
                        <ul class="list-inline mb-0">
                          
                          <li class="list-inline-item" >
                            <a
                              href="javascript:void(0);"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              @click="removeLane(element)"
                              class="btn btn-light btn-sm text-danger" 
                            >
                              <font-awesome-icon icon="fa-solid fa-trash-can" />
                              <div class="text-sm-end d-none d-md-inline-block ms-2">
                                {{ $t("customFields.deleteItem") }}
                              </div>
                            </a>
                          </li>
                        </ul>
                      </td>
                    </tr>
                  </template>
                </draggable>
              </table>
            </div>
          </div>
        </div> 
      </div>
    </div>
    <div class="card card-body m-0 footer-page-actions" style="">
      <div class="row gy-2 gx-3 align-items-center  ">
        <div class="col">
          <router-link 
            to="/boards"
            class="btn btn-secondary"
          >
            {{$t('common.closeButtonTitle')}}
          </router-link>
        </div>
        <div class="col text-end">
          <button type="button" 
            class="btn btn-primary"
            :disabled="processing || loading" 
            @click="submit"
          >
            <i class="fa fa-spin fa-spinner me-2" v-if="processing"/>
            <i class="fa fa-save me-2" v-if="!processing"/>
            {{$t('common.saveButtonTitle')}}
          </button>
        </div>
      </div>
    </div>
  </Layout>
</template>
